.export-icon {
    margin-right: 8px;
}

.export-modal-section {
    display: flex;
    flex-direction: column;
}

.export-modal-section > span {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #131B1F;
    margin-bottom: 12px;
}

.export-modal-section > label {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #131B1F;
}

.notification-section {
    margin-top: 20px;
}
