.ant-table-expanded-row>td {
    padding: 0 !important;
}

.ant-select-dropdown {
    z-index: 100000 !important;
}

.ant-upload.ant-upload-select {
    margin-bottom: 0 !important;
}