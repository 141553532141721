.filter-container {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 8px;
    flex-wrap: wrap;
    gap: 8px;
}

@media (max-width:767.98px) {
    .filter-container {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 8px;
    }
}
