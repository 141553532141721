.permission-modal-body{
    max-height: 70vh;
}

.modal-section-container{
    padding: 12px 16px;
    maxHeight: 65vh;
    minHeight: 65vh;
    overflow: scroll;
}
.section-divider {
    border-right: 1px solid rgba(5, 5, 5, 0.06);
}
@media (max-width: 576px) {
    .permission-modal-body , .modal-section-container{
        max-height: unset;
        min-height: unset;
    }
    .modal-section-container{
        overflow: inherit;
    }
    .section-divider {
        border-right: 0;
        border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    }
}
