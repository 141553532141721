.modal-search-icon {
    color: #697386;
}

.modal-search-input {
    padding: 12px 18px !important;
    border-bottom: 1px solid rgba(16, 17, 18, 0.1) !important;
}

.modal-filter-box .ant-btn {
    padding: 0 4px !important;
    color: #02862F !important;
}

.modal-filter-container {
    background-color: #fff;
    border: 1px solid rgba(16, 17, 18, 0.1);
    border-radius: 4px;
}

.filter-option-container {
    padding: 8px 16px;
    max-height: 100px;
    overflow: scroll;
}

.filter-option {
    margin-bottom: 12px;
}

.ant-collapse-header, .ant-collapse-content-box {
    padding: 8px 0 !important;
    align-items: center !important;
}

.ant-collapse-header-text {
    padding-top: 2px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #131B1F;
}
