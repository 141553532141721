.report-title-section {
    margin: 32px 0 24px;
}

.report-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: rgba(16, 17, 18, 0.9);
    margin-bottom: 4px;
}

.report-description {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: rgba(16, 17, 18, 0.6);
}