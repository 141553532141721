.customers-dropdown, .merchants-dropdown {
    padding: 0 !important;
}

.customers-dropdown .ant-select-item, .merchants-dropdown .ant-select-item {
    padding: 12px 0 !important;
    border-bottom: 1px solid #DCDDDD;
    border-radius: 0;
}

.customers-dropdown .ant-select-item-option-selected .ant-select-item-option-content span,
.merchants-dropdown .customers-dropdown .ant-select-item-option-selected .ant-select-item-option-content span {
    color: #000 !important;
}

.customers-dropdown .ant-select-item-option-selected, .merchants-dropdown .ant-select-item-option-selected {
    background: #F5F8F7 !important;
}
