.ant-table-thead {
  text-transform: capitalize;
}
.ant-table-thead > tr > th {
  font-weight: bold !important;
  font-size: 12px;
}

/* color of selected items in multi selection input*/
.ant-select-selection-overflow-item .ant-select-selection-item {
  background: #d6ebdd !important;
  color: #02862f;
}

.ant-menu-item-selected {
  background: #daeade !important;
}

.ant-layout {
  background: #fff !important;
}

.ant-layout-sider-children .ant-menu.ant-menu-root.ant-menu-vertical {
  border-inline-end: none !important;
}

/* specify nested rows*/
.ant-table-row-level-1 {
  background-color: #f5f8f7 !important;
}

.ant-select-selection-item-content {
  color: #02862f !important;
}

/* specify modal body */
.ant-modal-confirm-content {
  padding: 15px 0;
}
.ant-modal-confirm .ant-modal-confirm-paragraph {
  max-width: unset !important;
}

@media (max-width: 480px) {
  .ant-picker-dropdown-range {
    width: 320px;
  }
  .ant-picker-datetime-panel,
  .ant-picker-panels {
    flex-direction: column;
  }
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}
.ant-drawer-body {
  background-color: #f5f8f7;
}

.ant-alert {
  flex-wrap: wrap;
}
.ant-alert-content {
  min-width: 200px !important;
}

.ant-timeline-item-head {
  padding: 0 !important;
}

.border {
  border: 1px solid #e3e6f0 !important;
}

.rounded {
  border-radius: 0.35rem !important;
}

* {
  box-sizing: border-box;
}

