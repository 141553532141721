.company-activity .ant-timeline-item-tail {
    border-inline-start-style: dashed;
}

.company-activity .ant-timeline-item-head {
    border-radius: 5px;
    padding: 10px;
}

.company-activity .ant-timeline-item-content {
    inset-block-start: -16px !important;
}

.company-activity li:last-child .ant-timeline-item-head{
    display: none;
}
