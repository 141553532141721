@media print {
    @page {
        margin: 24px 0 16px;
        size: auto;
    }

    @page :first {
        margin-top: 0;
    }

    body > *:not(.print-container) {
        display: none !important;
    }

    .print-container {
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
        width: 100% !important;
        padding: 0 !important;
    }

    .print-container .ant-drawer-content {
        position: relative !important;
        height: auto !important;
        overflow: visible !important;
        background: white !important;
    }

    .print-container .ant-drawer-body {
        padding: 0px !important;
        height: auto !important;
        max-height: none !important;
        overflow: visible !important;
    }

    .print-container .print-button {
        display: none !important;
    }

    .no-print, .ant-drawer-close {
        display: none !important;
    }

    .print {
        display: block !important;
    }

    .print-container .ant-drawer-header {
        border-bottom: 1px solid rgba(5, 5, 5, 0.06);
        padding: 0 12px;
    }

    .print-container ol {
        list-style-type: decimal !important;
        padding-left: 16px;
    }

    .print-container ul {
        list-style-type: disc !important;
        padding-left: 16px;
    }

    #report-content {
        padding-bottom: 0 !important;
    }

}

@media screen {
    .print {
        display: none !important;
    }
}

#report-content .ant-drawer-body {
    padding: 0 0 80px 0;
}

#report-content ul {
    list-style-type: disc;
    padding-left: 0;
    list-style-position: inside;
}

#report-content ol {
    list-style-type: decimal;
    padding-left: 0;
    list-style-position: inside;
}

#report-content li {
    margin-bottom: 4px;
}
