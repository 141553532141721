
@font-face {
  font-family: 'Menlo';
  src: url('./assets/fonts/Menlo-Regular.ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Menlo';
  src: url('./assets/fonts/Menlo-Regular.woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-Medium.ttf');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-SemiBold.ttf');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-Bold.ttf');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-ExtraBold.ttf');
  font-weight: 800;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  font-family: 'Manrope', sans-serif !important;
}

.sidebar #sidebarToggle::after {
  content: '';
}

.sidebar.toggled #sidebarToggle::after {
  content: '';
  margin-left: 0;
}

* {
  font-family: 'Quicksand', sans-serif;
}

#wrapper #content-wrapper {
  overflow-x: unset !important;
}

.sidebar {
  position: fixed;
  min-height: 100vh !important;
}

#wrapper #content-wrapper {
  padding-left: 224px;
}

.fixed-width {
  width: 250px !important;
}

.sidebar .nav-item .collapse {
  margin: 1rem !important;
}

.pagination {
  float: right;
}

.bg-gradient-primary {
  background-color: #fff !important;
  background-image: linear-gradient(180deg, #fff 10%, #fff 100%) !important;
  background-size: cover;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
  color: #f16630 !important;
  font-weight: 700;
}

.sidebar .nav-item .collapse .collapse-inner {
  background-color: #f2f2f2 !important;
}

.sidebar-light .nav-item .nav-link i {
  color: #474747 !important;
}

.sidebar-light .nav-item .nav-link {
  color: #474747 !important;
}

.sidebar-light .nav-item.active .nav-link i {
  color: #04b261 !important;
}

.sidebar-light .nav-item.active .nav-link {
  color: #04b261 !important;
}

.sidebar-light .nav-item .collapse-item.active i {
  color: #04b261 !important;
}

a:hover {
  /* color: #f7a630 !important; */
}

a {
  /* color: #f16630 !important; */
}

.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {
  color: #3a3b45 !important;
}

.switch-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

input[type='checkbox'].switch {
  position: absolute;
  opacity: 0;
}

input[type='checkbox'].switch + div {
  vertical-align: middle;
  width: 40px;
  height: 20px;
  border-radius: 999px;
  background-color: #00b0f2;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: background-color, box-shadow;
  transition-property: background-color, box-shadow;
  cursor: pointer;
}

input[type='checkbox'].switch + div span {
  position: absolute;
  font-size: 1.6rem;
  color: white;
  margin-top: 12px;
}

input[type='checkbox'].switch + div span:nth-child(1) {
  margin-left: 15px;
}

input[type='checkbox'].switch + div span:nth-child(2) {
  margin-left: 55px;
}

input[type='checkbox'].switch:checked + div {
  width: 94px;
  background-position: 0 0;
  background-color: #3b89ec;
}

input[type='checkbox'].switch + div {
  width: 94px;
  height: 48px;
}

input[type='checkbox'].switch:checked + div {
  background-color: #57bb00;
}

input[type='checkbox'].switch + div > div {
  float: left;
  width: 46px;
  height: 46px;
  border-radius: inherit;
  background: #ffffff;
  -webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: transform, background-color;
  transition-property: transform, background-color;
  pointer-events: none;
  margin-top: 1px;
  margin-left: 1px;
}

input[type='checkbox'].switch:checked + div > div {
  -webkit-transform: translate3d(20px, 0, 0);
  transform: translate3d(20px, 0, 0);
  background-color: #ffffff;
}

input[type='checkbox'].bigswitch.switch + div > div {
  width: 44px;
  height: 44px;
  margin-top: 1px;
}

input[type='checkbox'].switch:checked + div > div {
  -webkit-transform: translate3d(46px, 0, 0);
  transform: translate3d(46px, 0, 0);
}

.react_times_button {
  box-shadow: none !important;
  border: 1px solid #9997 !important;
  border-radius: 50px !important;
}

.time_picker_modal_header {
  flex: 1;
  width: 100%;
}

.modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal {
  z-index: 99999999999 !important;
}

/* Remove Arrows/Spinners */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ant-input-prefix {
  color: rgba(0, 0, 0, 0.45)
}

.hidden {
  display: none !important;
}