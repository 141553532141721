.merchant-name-column {
    color: #000;
    padding: 0;
}

.merchant-name {
    max-width: 250px !important;
    color: inherit;
    margin-right: 4px;
}

.merchant-name-column svg {
    display: none;
}
.merchant-name-column:hover  {
    color: #02862F;
}

.merchant-name-column:hover svg {
    display: inline-block;
}