.toolbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 32px;
    align-items: flex-start;
}
@media (max-width:767.98px) {
    .toolbar-container {
        flex-direction: column;
        align-items: flex-start;
    }
}
